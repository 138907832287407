.linkedin:hover {
  color: #0077b5;
}
.instagram:hover {
  color: #c13584;
}
.tree:hover {
  color: #39e09b;
}
.twitter:hover {
  color: #00acee;
}
.facebook:hover {
  color: #3b5998!important;
}