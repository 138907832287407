.itemContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;
}
.item {
  flex: 0 0 calc(100% / 6);
}

@media (min-width: 1280px) {
  .item {
    flex: 0 0 calc(100% / 7);
  }
}

@media (max-width: 1024px) {
  .item {
    flex: 0 0 calc(100% / 5);
  }
}
@media (max-width: 768px) {
  .item {
    flex: 0 0 calc(100% / 4);
  }
}
@media (max-width: 640px) {
  .item {
    flex: 0 0 calc(100% / 3);
  }
}
