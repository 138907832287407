section.hero {
  position: relative;
  display: flex;
}
.btn {
  font-size: 14px;
  border-radius: 1rem;
  padding: 1rem 1.75rem;
  text-align: center;
  line-height: 1.25rem;
  text-transform: uppercase;
}
