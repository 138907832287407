@tailwind base;
@layer base {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
@layer base {
  h1 {
    @apply text-3xl;
    @apply font-mulishBold;
  }
  h2 {
    @apply text-2xl;
    @apply font-mulishBold;
  }
  h3 {
    @apply text-xl;
    @apply font-mulishBold;
  }
}
@tailwind components;
@font-face {
  font-family: "Mulish-Regular";
  font-display: swap;
  src: local("Mulish-Regular"),
    url("./assets/fonts/Mulish-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Mulish-Bold";
  font-display: swap;
  src: local("Mulish-Bold"),
    url("./assets/fonts/Mulish-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Mulish-ExtraBold";
  font-display: swap;
  src: local("Mulish-ExtraBold"),
    url("./assets/fonts/Mulish-ExtraBold.ttf") format("truetype");
}
@font-face {
  font-family: "Mulish-Medium";
  font-display: swap;
  src: local("Mulish-Medium"),
    url("./assets/fonts/Mulish-Medium.ttf") format("truetype");
}

@tailwind utilities;

.container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 1rem;
  padding-left: 1rem;
}
.default-list ul,
.default-list ol {
  list-style: revert;
}
