.section-title {
  margin-bottom: 40px;
}
.section-title h6 {
  margin-bottom: 0.75rem;
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-weight: 800;
}
.section-title h4 {
  font-size: 40px;
}
@media (max-width: 1024px) {
  .section-title h4 {
    font-size: 30px;
  }
}
@media (max-width: 640px) {
  .section-title h4 {
    font-size: 24px;
  }
}
