.scrollToTop {
  bottom: 30px !important;
  right: 20px !important;
  height: 3.5rem !important;
  width: 3.5rem !important;
  border-radius: 9999px !important;
  padding: 15px;
  background-color: black !important;
  color: white;
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter,
    backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  animation: bounce 1s infinite;
}
.scrollToTop:hover {
  background-color: #01c6f0 !important;
}
@keyframes bounce {
  0%,
  100% {
    transform: translateY(-25%);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }
  50% {
    transform: none;
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
}
