
@media (max-width: 1024px) {
  .fix-screen {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
}
.open-menu {
  position: absolute;
  top: 100%;
  z-index: 20;
  width: 100%;
  display: block;
}
.close-menu {
  display: none;
}
